import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as global from '../global-functions';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  constructor(private router: Router) {
  }

  ngOnInit() {
     if(!global.getCookie('access_token')) {
       this.router.navigate(['']);
     }
  }

}
