import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
declare var DVM: any;
declare var $:any;
import * as json_categories from '../../assets/json/categories.json';
import * as json_fcbv1 from '../../assets/json/fcbarcelona.json';
import * as json_fcbv1_champ from '../../assets/json/fcbarcelona_champions.json';
import * as json_fcbv2 from '../../assets/json/fcbarcelonav2.json';
import * as json_fcbv3 from '../../assets/json/fcbarcelonamig.json';
import * as json_fcbv3_champ from '../../assets/json/fcbarcelonamig_champions.json';
import * as global from '../global-functions';


@Injectable({
    providedIn: 'root'
})
export class MapService {
    private listOfTiers = new Subject<any>();
    private listOfFases = new Subject<any>();
    private seating = new Subject<string>();

    current_view = 'topview';
    current_section = null;
    nseats = 1;

    viewer_promise:any;
    viewer3d_promise:any;
    viewer: any;
    view3d_module:any;
    venue_id: string;
    map_id: string;

    content;
    section_val:string;
    row_val:string;
    seat_val:string;
    seatIDval:string;

    actualVenue:string;
    actualMap:string;

    popover_timeout = null;

    sectionCategoryV1 = null;
    sectionCategoryV1_C = null;
    sectionCategoryV2 = null;
    sectionCategoryV3 = null;
    sectionCategoryV3_C = null;

    childrens = {};

    constructor() {
        this.viewer_promise = DVM.loadModule("map_viewer", this.instance_options);
        this.viewer3d_promise = DVM.loadModule("3d_viewer", this.viewer3d_input_options);

        Promise.all([this.viewer_promise, this.viewer3d_promise])
        .then((result) => {
            this.viewer = result[0];
            this.view3d_module = result[1];

            this.viewer.flags.fixed_aspect_ratio = false;
            
            this.bindInterfaceEvents();

        })
        .catch((err) => {
            console.error(err);
        });

        if(this.sectionCategoryV2 == null) {
            this.sectionCategoryV2 = {};
            for(let category in json_fcbv2.default) {
                this.sectionCategoryV2[category] = {};
                for(let i = 0; i < json_fcbv2.default[category].length; i ++) {
                    const seatid = json_fcbv2.default[category][i];
                    const sectionid = seatid.split("-")[0];
                    if(this.sectionCategoryV2[category][sectionid] == null) {
                        this.sectionCategoryV2[category][sectionid] = [];
                    }
                    this.sectionCategoryV2[category][sectionid].push(seatid);
                }
            }
        }

        if(this.sectionCategoryV1 == null) {
            this.sectionCategoryV1 = {};
            for(let category in json_fcbv1.default) {
                this.sectionCategoryV1[category] = {};
                for(let i = 0; i < json_fcbv1.default[category].length; i ++) {
                    const seatid = json_fcbv1.default[category][i];
                    const sectionid = seatid.split("-")[0];
                    if(this.sectionCategoryV1[category][sectionid] == null) {
                        this.sectionCategoryV1[category][sectionid] = [];
                    }
                    this.sectionCategoryV1[category][sectionid].push(seatid);
                }
            }
        }

        if(this.sectionCategoryV1_C == null) {
            this.sectionCategoryV1_C = {};
            for(let category in json_fcbv1_champ.default) {
                this.sectionCategoryV1_C[category] = {};
                for(let i = 0; i < json_fcbv1_champ.default[category].length; i ++) {
                    const seatid = json_fcbv1_champ.default[category][i];
                    const sectionid = seatid.split("-")[0];
                    if(this.sectionCategoryV1_C[category][sectionid] == null) {
                        this.sectionCategoryV1_C[category][sectionid] = [];
                    }
                    this.sectionCategoryV1_C[category][sectionid].push(seatid);
                }
            }
        }

        if(this.sectionCategoryV3 == null) {
            this.sectionCategoryV3 = {};
            for(let category in json_fcbv3.default) {
                this.sectionCategoryV3[category] = {};
                for(let i = 0; i < json_fcbv3.default[category].length; i ++) {
                    const seatid = json_fcbv3.default[category][i];
                    const sectionid = seatid.split("-")[0];
                    if(this.sectionCategoryV3[category][sectionid] == null) {
                        this.sectionCategoryV3[category][sectionid] = [];
                    }
                    this.sectionCategoryV3[category][sectionid].push(seatid);
                }
            }
        }

        if(this.sectionCategoryV3_C == null) {
            this.sectionCategoryV3_C = {};
            for(let category in json_fcbv3_champ.default) {
                this.sectionCategoryV3_C[category] = {};
                for(let i = 0; i < json_fcbv3_champ.default[category].length; i ++) {
                    const seatid = json_fcbv3_champ.default[category][i];
                    const sectionid = seatid.split("-")[0];
                    if(this.sectionCategoryV3_C[category][sectionid] == null) {
                        this.sectionCategoryV3_C[category][sectionid] = [];
                    }
                    this.sectionCategoryV3_C[category][sectionid].push(seatid);
                }
            }
        }
    }

    bindInterfaceEvents(){
        var zoom_in_btn = document.getElementsByClassName("zoom-in-btn")[0];
        var zoom_out_btn = document.getElementsByClassName("zoom-out-btn")[0];
    
        this.viewer.bindInterfaceAction(zoom_in_btn, "zoom-in");
        this.viewer.bindInterfaceAction(zoom_out_btn, "zoom-out");
    
        var move_left_btn = document.getElementById("move-left");
        var move_right_btn = document.getElementById("move-right");
        var move_up_btn = document.getElementById("move-up");
        var move_down_btn = document.getElementById("move-down");
    
        this.viewer.bindInterfaceAction(move_left_btn, "move-left");
        this.viewer.bindInterfaceAction(move_right_btn, "move-right");
        this.viewer.bindInterfaceAction(move_up_btn, "move-up");
        this.viewer.bindInterfaceAction(move_down_btn, "move-down");
    }

    setFases(obj) {
        this.listOfFases.next(obj);
    }   

    getFases() {
        return this.listOfFases.asObservable();
    }

    setTiers(obj) {
        this.listOfTiers.next(obj);
    }   

    getTiers() {
        return this.listOfTiers.asObservable();
    }

    setSeating(value: string): void {
        this.seating.next(value);
    }

    getSeating(): Observable<any> {
        return this.seating.asObservable();
    }

    windowLoop = windowLoop.bind(this);
    general_config = {
        need_reset_zoom: false,
        language: "en",
        window:{
            innerWidth: window.innerWidth,
            innerHeight: window.innerHeight,
            animation: requestAnimationFrame(this.windowLoop)
        }
    };

    /* D2M */
    instance_options = {
        version: "development",
        container: "blockmap",
        callbacks: {
            click: result => this.onClick(result),
            enter: e => {
                var node = e.nodes[0];
                if(node && node.type === "seat" && node.state !== 'unavailable'){
                    this.popover_timeout = setTimeout(() => {
                        this.tooltipOpen(node);
                        this.popover_timeout = null;
                    },100);
                }
            },
            leave: e => {    
                if(this.popover_timeout) {
                    clearTimeout(this.popover_timeout);
                    this.popover_timeout = null;
                }
                else{
                    this.tooltipDestroy();
                } 
            }
        }
    };

    viewer3d_input_options = {
        container: "view3d",
        callbacks: {
            fullscreen_enabled: result => {
                this.view3d_module.flags.show_fullscreen_button = true;
                document.getElementById("view3d").classList.remove("d-none");
            },
            fullscreen_disabled: result => {
                document.getElementById("view3d").classList.add("d-none");
                this.view3d_module.flags.show_fullscreen_button = false;
            } 
        }
    };

    goHome() {
        this.loadMap(this.actualVenue,this.actualMap);
    }

    loadMap(venue_id:string, map_id:string){
        this.current_view = "topview";
        this.venue_id = venue_id;
        this.map_id = map_id;

        this.viewer.loadMap({ venue_id: this.venue_id, map_id: this.map_id })
            .then(result => this.onLoadD2MSuccess(result))
            .catch(err => this.onLoadD2MError(err));

        this.actualVenue = venue_id;
        this.actualMap = map_id;
        (window as any).viewer = this.viewer;

        return null;
    }

    setNodesTag(category_seats:Seats) {
        const viewer = this.viewer;
        if (!viewer.isLoaded()) {
            throw new Error("Map not loaded");
        }

        for (const category_id in category_seats) {
            const seats = category_seats[category_id];
            viewer.setNodesTag(seats, category_id);
        }
    }

    parseCategories(categories:Categories){
        const viewer = this.viewer;
        if (!viewer.isLoaded()) {
            throw new Error("Map not loaded");
        }
        const styles = viewer.getStyles() as any;
        let normal_available;
        let normal_default_tag;
        let hover_available;
        let hover_default_tag;

        for(var i = 0; i < styles.length; i++) {
            normal_available = styles && styles[i] && styles[i].seat && styles[i].seat.available && styles[i].seat.available.normal && styles[i].seat.available.normal.none ?
                styles[i].seat.available.normal : null;
            normal_default_tag = normal_available ?normal_available.none : null;
            hover_available = styles && styles[i] && styles[i].seat && styles[i].seat.available && styles[i].seat.available.normal && styles[i].seat.available.normal.none ?
                styles[i].seat.available.hover : null;
            hover_default_tag = hover_available ? hover_available.none : null;
        }

        for (const category_id in categories) {
            const category = categories[category_id];
            if (normal_available && normal_default_tag) {
                normal_available[category_id] = Object.assign({}, normal_default_tag, category.styles);
            }

            if (hover_available && hover_default_tag) {
                hover_available[category_id] = Object.assign({}, hover_default_tag, category.styles);
            }
        }

        viewer.setStyles(styles);
    }

    onLoadD2MSuccess(obj) {
        this.viewer.flags.automatic_selection = false;
        this.setFullAvailability(obj.instance);
        this.viewer.max_selection = 999;
        this.parseCategories(json_categories.default);
        var actualVenue = this.viewer.getVenueId();

        if(actualVenue === "eu-es-00001-fcbarcelona") {
            this.setNodesTag(json_fcbv1.default);
        } else if(actualVenue === "eu-es-00001-fcbarcelonamig") {
            this.setNodesTag(json_fcbv3.default);
        } else if(actualVenue === "eu-es-00001-fcbarcelonav2") {
            this.setNodesTag(json_fcbv2.default);
        }
    }

    onLoadD2MError(err) {
        console.log(err);
    }

    setFullAvailability(instance) {
        if (instance.isLoaded()) {
            var node_types = instance.getTypesList();
            node_types.forEach(function(type) {
                instance.setAvailability(type, instance.getNodesByType(type));
            });
        }
    }

    onClick(obj) {
        var node = obj.nodes[0];

        if (node) {
            if ((node.type === "section" || node.type === "areaprice") && node.state != "unavailable") {
                if(node.state === "selected") {
                    this.viewer.unselect(node);
                    this.removeChildrensOnSector(node);
                } else {
                    this.viewer.select(node);
                    if($(".sectionPopover").hasClass('d-none')) {
                        this.popoverCreate(node);
                    }
                    this.calcChildrensOnSector(node);
                    this.calcTotalSeatsOnSector();
                }
            } else if (node.type === "seat" && node.state !== 'unavailable') { 
                if(node.state === "selected") {
                    this.viewer.unselect(node);
                    this.popoverDestroy();
                } else {
                    this.viewer.unselectAll();
                    this.viewer.select(node);
                    this.popoverCreate(node);
                }
            }
        }
    }

    total:number = 0;

    calcTotalSeatsOnSector() {
        this.total = 0;

        for(let i in this.childrens) {
            this.total += this.childrens[i].num;
        }

        return this.total;
    }

    removeChildrensOnSector(node){
        let count = 0;
        let name;

        if(this.viewer) {
            if(this.viewer.getVenueId() === "eu-es-00001-fcbarcelona") {
                for(let i in this.sectionCategoryV1) {
                    if(this.sectionCategoryV1[i][node.id]) {
                        count = this.sectionCategoryV1[i][node.id].length;
                        name = json_categories.default[i].name;
                        var color = json_categories.default[i].styles.fillStyle;
                        if(!this.childrens.hasOwnProperty(name)){
                            this.childrens[name] = {id:name, num:count, color:color};
                            continue;
                        }
                        this.childrens[name].num -= count;
                    }
                }
                for(let i in this.sectionCategoryV1_C) {
                    if(this.sectionCategoryV1_C[i][node.id]) {
                        count = this.sectionCategoryV1_C[i][node.id].length;
                        name = json_categories.default[i].name;
                        var color = json_categories.default[i].styles.fillStyle;
                        if(!this.childrens.hasOwnProperty(name)){
                            this.childrens[name] = {id:name, num:count, color:color};
                            continue;
                        }
                        this.childrens[name].num -= count;
                    }
                }

            } else if(this.viewer.getVenueId() === "eu-es-00001-fcbarcelonamig") {
                for(let i in this.sectionCategoryV3) {
                    if(this.sectionCategoryV3[i][node.id]) {
                        count = this.sectionCategoryV3[i][node.id].length;
                        name = json_categories.default[i].name;
                        var color = json_categories.default[i].styles.fillStyle;
                        if(!this.childrens.hasOwnProperty(name)){
                            this.childrens[name] = {id:name, num:count, color:color};
                            continue;
                        }
                        this.childrens[name].num -= count;
                    }
                }
                for(let i in this.sectionCategoryV3_C) {
                    if(this.sectionCategoryV3_C[i][node.id]) {
                        count = this.sectionCategoryV3_C[i][node.id].length;
                        name = json_categories.default[i].name;
                        var color = json_categories.default[i].styles.fillStyle;
                        if(!this.childrens.hasOwnProperty(name)){
                            this.childrens[name] = {id:name, num:count, color:color};
                            continue;
                        }
                        this.childrens[name].num -= count;
                    }
                }
            } else if (this.viewer.getVenueId() === "eu-es-00001-fcbarcelonav2") {
                for(let i in this.sectionCategoryV2) {
                    if(this.sectionCategoryV2[i][node.id]) {
                        count = this.sectionCategoryV2[i][node.id].length;
                        name = json_categories.default[i].name;
                        var color = json_categories.default[i].styles.fillStyle;
                        if(!this.childrens.hasOwnProperty(name)){
                            this.childrens[name] = {id:name, num:count, color:color};
                            continue;
                        }
                        this.childrens[name].num -= count;
                    }
                }

            }

            if(this.viewer.getNodesByState("section","selected").length === 0) {
                this.resetSelection();
            }
        }
    }

    calcChildrensOnSector(node){
        let count = 0;
        let name;

        if(this.viewer) {
            if(this.viewer.getVenueId() === "eu-es-00001-fcbarcelona") {
                for(let i in this.sectionCategoryV1) {
                    if(this.sectionCategoryV1[i][node.id]) {
                        count = this.sectionCategoryV1[i][node.id].length;
                        name = json_categories.default[i].name;
                        var color = json_categories.default[i].styles.fillStyle;
                        if(!this.childrens.hasOwnProperty(name)){
                            this.childrens[name] = {id:name, num:count, color:color};
                            continue;
                        }
                        this.childrens[name].num += count;
                    }
                }
                for(let i in this.sectionCategoryV1_C) {
                    if(this.sectionCategoryV1_C[i][node.id]) {
                        count = this.sectionCategoryV1_C[i][node.id].length;
                        name = json_categories.default[i].name;
                        var color = json_categories.default[i].styles.fillStyle;
                        if(!this.childrens.hasOwnProperty(name)){
                            this.childrens[name] = {id:name, num:count, color:color};
                            continue;
                        }
                        this.childrens[name].num += count;
                    }
                }
            } else if(this.viewer.getVenueId() === "eu-es-00001-fcbarcelonamig") {
                for(let i in this.sectionCategoryV3) {
                    if(this.sectionCategoryV3[i][node.id]) {
                        count = this.sectionCategoryV3[i][node.id].length;
                        name = json_categories.default[i].name;
                        var color = json_categories.default[i].styles.fillStyle;
                        if(!this.childrens.hasOwnProperty(name)){
                            this.childrens[name] = {id:name, num:count, color:color};
                            continue;
                        }
                        this.childrens[name].num += count;
                    }
                }
                for(let i in this.sectionCategoryV3_C) {
                    if(this.sectionCategoryV3_C[i][node.id]) {
                        count = this.sectionCategoryV3_C[i][node.id].length;
                        name = json_categories.default[i].name;
                        var color = json_categories.default[i].styles.fillStyle;
                        if(!this.childrens.hasOwnProperty(name)){
                            this.childrens[name] = {id:name, num:count, color:color};
                            continue;
                        }
                        this.childrens[name].num += count;
                    }
                }
            } else if (this.viewer.getVenueId() === "eu-es-00001-fcbarcelonav2") {
                for(let i in this.sectionCategoryV2) {
                    if(this.sectionCategoryV2[i][node.id]) {
                        count = this.sectionCategoryV2[i][node.id].length;
                        name = json_categories.default[i].name;
                        var color = json_categories.default[i].styles.fillStyle;
                        if(!this.childrens.hasOwnProperty(name)){
                            this.childrens[name] = {id:name, num:count, color:color};
                            continue;
                        }
                        this.childrens[name].num += count;
                    }
                }
            }
        }
    }

    resetSelection() {
        this.childrens = {};
        this.viewer.unselectAll();
        this.popoverDestroy();
    }

    viewerGoTo(event) {
        var id = event.currentTarget.dataset.seatid;
        this.viewer.goTo(id);
    }

    viewerLoad360FromElement(event) {
        var id = event.currentTarget.dataset.seatid;
        this.viewerLoad360(id, true);
    }

    viewerLoad360(seatid, fullscreen) {
        var obj = {
            venue_id: this.venue_id,
            view_id: seatid
        }

        let that = this;
        that.view3d_module.close();
        that.view3d_module.loadView3d(obj)
            .then(function() {
                if (fullscreen) {
                    that.view3d_module.open();
                    that.view3d_module.toggleFullscreen()
                }
            })
    }

    popoverCreate(node) {
        this.popoverDestroy();
        this.section_val = node.id.split("-")[0].split("_")[1];
        this.row_val = node.id.split("-")[1];
        this.seat_val = node.id.split("-")[2];
        this.seatIDval = node.id;
        var catName;
        this.setSeating(this.seatIDval);
        var actualVenue = this.viewer.getVenueId();

        if(node.type === "section") {
            $(".sectionPopover").removeClass('d-none');
        } else {
            if(actualVenue === "eu-es-00001-fcbarcelona") {
                for(var seat in json_fcbv1.default){
                    if(json_fcbv1.default[seat].indexOf(node.id) !== -1) {
                        catName = json_categories.default[seat].name;
                        break;
                    } else {
                        catName = "Default";
                    }
                }
            } else if(actualVenue === "eu-es-00001-fcbarcelonamig") {
                for(var seat in json_fcbv3.default){
                    if(json_fcbv3.default[seat].indexOf(node.id) !== -1) {
                        catName = json_categories.default[seat].name;
                        break;
                    } else {
                        catName = "Default";
                    }
                }
            } else if(actualVenue === "eu-es-00001-fcbarcelonav2") {
                for(var seat in json_fcbv2.default){
                    if(json_fcbv2.default[seat].indexOf(node.id) !== -1) {
                        catName = json_categories.default[seat].name;
                        break;
                    } else {
                        catName = "Default";
                    }
                }
            }
            $(".cat-name").text("");
            $(".cat-name").text(catName);
            $(".seatPopover").removeClass('d-none');
        }
    }

    popoverDestroy() {
        $(".seatPopover").addClass("d-none");
        $(".sectionPopover").addClass("d-none");
    }

    tooltipDestroy() {
        $(".tooltip").remove();
    }

    tooltipOpen(node) {
        this.tooltipDestroy();
        let template;
        if(node.type === "seat") {
            template = this.tooltipGetContentSeat(node);
        }

        if(template){
            var popover = $("<div class='tooltip text-center tootltip-item'>"+template+"</div>");
            this.tooltipMove(popover,node);
            $("#main-interface").append(popover);
        }
    }

    tooltipMove(popover,node){
        if(popover){
            var w = popover.width();
            var h = popover.height();
            h = $("#header-wrap").innerHeight();

            var top;
            top = node.anchor.dom[1] + h - 40;
    
            var left;
            left = node.anchor.dom[0] - 50;
    
            popover.css("top",top);
            popover.css("left",left);
        }

        return null;
    }

    tooltipGetContentSeat(node) {
        var split = node.id.split("-");
        var section = split[0].split("_")[1];
        var row = split[1];
        var seat = split[2];
        var content = "<div class='tooltip-data'>" + section + " - " + row + " - " + seat; + "</div>";
        
        return content;
    }

    getGeneralInfo() {
        let count;
        let name;
        let cat = [];
        if(this.viewer) {
            if(this.viewer.getVenueId() === "eu-es-00001-fcbarcelona") {
                for(let i in json_fcbv1.default){
                    count = json_fcbv1.default[i].length;
                    name = json_categories.default[i].name;
                    if(json_categories.default[i]){
                        var color = json_categories.default[i].styles.fillStyle;
                        cat.push({id:name,num:count,color:color});
                    }
                }
            } else if(this.viewer.getVenueId() === "eu-es-00001-fcbarcelonamig") {
                for(let i in json_fcbv3.default){
                    count = json_fcbv3.default[i].length;
                    name = json_categories.default[i].name;
                    if(json_categories.default[i]){
                        var color = json_categories.default[i].styles.fillStyle;
                        cat.push({id:name,num:count,color:color});
                    }
                }
            } else if (this.viewer.getVenueId() === "eu-es-00001-fcbarcelonav2") {
                for(let i in json_fcbv2.default){
                    count = json_fcbv2.default[i].length;
                    name = json_categories.default[i].name;
                    if(json_categories.default[i]){
                        var color = json_categories.default[i].styles.fillStyle;
                        cat.push({id:name,num:count,color:color});
                    }
                }
            }
        }

        return cat;
    }

    interfaceZoomHide(){
        $(".zoom-in-btn").addClass("d-none");
        $(".zoom-out-btn").addClass("d-none");
    }
    
    interfaceZoomShow(){
        $(".zoom-in-btn").removeClass("d-none");
        $(".zoom-out-btn").removeClass("d-none");
    }

    interfaceMoveArrowsShow(){
        $("#move-up").removeClass("d-none");
        $("#move-down").removeClass("d-none");
        $("#move-left").removeClass("d-none");
        $("#move-right").removeClass("d-none");
    }

    interfaceMoveArrowsHide(){
        $("#move-up").addClass("d-none");
        $("#move-down").addClass("d-none");
        $("#move-left").addClass("d-none");
        $("#move-right").addClass("d-none");
    }

    interfaceMoveArrowsHideOnLimits(){
        if(this.viewer.limits) {
            if(this.viewer.limits.left_limit) {
                $("#move-left").addClass("d-none");
            }
            
            if(this.viewer.limits.right_limit) {
                $("#move-right").addClass("d-none");
            }
            
            if(this.viewer.limits.upper_limit) {
                $("#move-up").addClass("d-none");
            }
            
            if(this.viewer.limits.lower_limit) {
                $("#move-down").addClass("d-none");
            }
        }
    }
}

function windowLoop() {
    if(global.getCookie('access_token')) {
        var h = window.innerHeight;
        var bh = h - (document.getElementById("header-wrap").clientHeight 
        + document.getElementById("footer").clientHeight);
        
        document.getElementById("main-interface").style.height = bh+"px";
        document.getElementById("blockmap").style.height = bh+"px";
        
        if(this.viewer && this.viewer.isLoaded() && this.current_view !== "view3d") {
            if(this.viewer.scaling_factor > this.viewer.min_scaling_factor) {
                this.interfaceMoveArrowsShow();
                this.interfaceMoveArrowsHideOnLimits();
            } else {
                this.interfaceMoveArrowsHide();
            }
        } else {
            this.interfaceMoveArrowsHide();
        }
    }
    this.general_config.window.animation = requestAnimationFrame(this.windowLoop);
}

interface Categories{
    [key:string]: {
        styles:{
            [key:string]: any;
        },
        id:string;
        name:string;
        info:string;
    }
}

interface Seats {
    [key:string]: string[];
}