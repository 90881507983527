import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as global from '../global-functions';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user: string = "admin";
  pass:string = "admin";

  username: string;
  password: string;

  errorMessage: boolean = false;

  constructor(private router: Router) { }

  ngOnInit() {
     if (global.getCookie("access_token")) {
       this.router.navigate(['/map']);
     }
  }

  

  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  validateLogin() {
    if(this.username === this.user && this.password === this.pass) {
      this.errorMessage = false;
      global.setCookie("access_token", this.makeid(40), 86400);
      this.router.navigate(['/map']);
    } else {
      this.errorMessage = true;
      console.log("error")
    }
  }
}
