import { Component, OnInit } from '@angular/core';
declare var $: any;
import * as json_list from '../../../assets/json/list.json';
import { MapService } from '../map.service.js';
import * as global from '../../global-functions';
import { Router } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  listOfTiers = {};
  listOfFases = {};

  constructor(private mapService: MapService, private router: Router) { }

  cat = [];

  onLogout() {
    global.deleteCookie('access_token');
    this.router.navigate(['']);
  }

  ngOnInit() {
    this.mapService.getTiers().subscribe(
      tiersList => {
        this.listOfTiers = tiersList;
      }
    )

    this.mapService.getFases().subscribe(
      fasesList => {
        this.listOfFases = fasesList;
      }
    )
  }

  get tiers() {
    return this.listOfTiers;
  }

  get fases() {
    return this.listOfFases;
  }

  selectedTier(event) {
    var item = $("#" + event.currentTarget.id + " .dropdown-item").find(".selected-tier");
    var text = event.currentTarget.textContent.trim();
    var map = json_list.default.tiers[text].map_id;
    for(var f in json_list.default.fases) {
      if(json_list.default.fases[f].name === $(".fase-name").text().trim()) {
        var venue = json_list.default.fases[f].venue_id;
      }
    }

    if(item.hasClass("ti-layout-width-full")) {
      $(".selected-tier").removeClass("ti-check-box").addClass("ti-layout-width-full");
      item.removeClass("ti-layout-width-full").addClass("ti-check-box");
    } else {
      item.removeClass("ti-check-box").addClass("ti-layout-width-full");
    }

    $(".tier-name").text(text+" ");
    this.infoPopoverDestroy();
    this.seatPopoverDestroy();
    this.mapService.loadMap(venue, map);
  }

  selectedFase(event) {
    var item = $("#" + event.currentTarget.id + " .dropdown-item").find(".selected-fase");
    var text = event.currentTarget.textContent.trim();
    for(var f in json_list.default.fases) {
      if(json_list.default.fases[f].name === text) {
        var venue = json_list.default.fases[f].venue_id;
      }
    }
    var map = json_list.default.tiers[$(".tier-name").text().trim()].map_id;
    if(item.hasClass("ti-layout-width-full")) {
      $(".selected-fase").removeClass("ti-check-box").addClass("ti-layout-width-full");
      item.removeClass("ti-layout-width-full").addClass("ti-check-box");
    } else {
      item.removeClass("ti-check-box").addClass("ti-layout-width-full");
    }

    this.infoPopoverDestroy();
    this.seatPopoverDestroy();
    $(".fase-name").text(text+" ");

    this.mapService.loadMap(venue, map);
  }

  total: number = 0;

  openSeatsInfo() {
    this.total = 0;
    this.cat = this.mapService.getGeneralInfo();
    for(var i = 0; i < this.cat.length; i++) {
      this.total += this.cat[i].num;
    }
    $(".infoPopover").toggleClass("d-none");
  }

  get categories() {
    return this.cat;
  }

  goHome() {
    this.infoPopoverDestroy();
    this.seatPopoverDestroy();
    this.mapService.goHome();
  }

  infoPopoverDestroy() {
    $(".infoPopover").addClass("d-none");
  }

  seatPopoverDestroy() {
    $(".sectionPopover").addClass("d-none");
    $(".seatPopover").addClass("d-none");
  }

  resetSelection() {
    this.mapService.resetSelection();
  }

}
