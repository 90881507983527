export function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

export function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    var time = parseInt(exdays) / 3600 / 24;
    d.setTime(d.getTime() + (time * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();

    document.cookie = cname + "=" + cvalue;
    document.cookie = expires;
}
  
export function checkCookie(name) {
    var token = getCookie(name);
    if (token != "") {
      return true;
    } else {
      return false;
    }
}

export function deleteCookie(name) {
    document.cookie = name+"=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}