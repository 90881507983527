import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PlatformLocation } from '@angular/common';
import { MapService } from '../map.service.js';
import { Router } from '@angular/router';
import * as json_list from '../../../assets/json/list.json';
import * as global from '../../global-functions';
declare var $:any;


@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

    constructor (private modalService: BsModalService,
                 private mapService: MapService, 
                 private location: PlatformLocation,
                 private router: Router) { 
        location.onPopState(() => {this.modalRef.hide();})
    }

    @ViewChild('mapSelection', {static: true}) modal: TemplateRef<any>;
    modalRef: BsModalRef;
    modalConfig = {
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-dialog-centered'
    };

    tier;
    fase;

    childrenSeats: any;

    section_val:string;
    row_val:string;
    seat_val:string;
    seatIDval:string;

    listTiers = [];
    listFases = [];

    ngOnInit(): void {
        if(!global.getCookie('access_token')) {
            this.router.navigate(['']);
        } else {
            this.modalRef = this.modalService.show(this.modal, this.modalConfig);
            this.mapService.getSeating().subscribe(
                seating => {
                    this.seatIDval = seating;
                    this.section_val = this.seatIDval.split("-")[0].split("_")[1];
                    this.row_val = this.seatIDval.split("-")[1];
                    this.seat_val = this.seatIDval.split("-")[2];
                }
            )
    
            for (let tier of Object.keys(json_list.default.tiers)) {
                this.listTiers.push({name: tier, check: false});
            }
            this.mapService.setTiers(this.listTiers);
    
            for (let fase of Object.keys(json_list.default.fases)) {
                this.listFases.push({name: json_list.default.fases[fase].name, check: false});
            }
            this.mapService.setTiers(this.listFases);
        }
    }

    get childrens(){
        this.childrenSeats = this.mapService.childrens;
        return Object.keys(this.childrenSeats);
    }

    get total() {
        return this.mapService.calcTotalSeatsOnSector();
    }

    get tiers() {
        var tier = [];

        for(var i in json_list.default.tiers) {
            tier.push(json_list.default.tiers[i].name);
        }

        return tier;
    }

    get fases() {
        var fase = [];

        for(var i in json_list.default.fases) {
            fase.push(json_list.default.fases[i].name);
        }

        return fase;
    }

    selectTier(event) {
        this.tier = json_list.default.tiers[event.target.value];
    }

    selectFase(event) {
        for(var i in json_list.default.fases) {
            if(event.target.value === json_list.default.fases[i].name) {
                this.fase = json_list.default.fases[i];
            }
        }
    }
    
    accept() {
        var venue_id = this.fase.venue_id;
        var map_id = this.tier.map_id;
        $(".tier-name").text(this.tier.name+" ");
        $(".fase-name").text(this.fase.name+" ");
        
        for (let i in this.listTiers) {
            if(this.tier.name === this.listTiers[i].name){
                this.listTiers[i].check = true;
                break;
            }
        }
        this.mapService.setTiers(this.listTiers);

        for (let i in this.listFases) {
            if(this.fase.name === this.listFases[i].name){
                this.listFases[i].check = true;
                break;
            }
        }

        this.mapService.setFases(this.listFases);
        this.mapService.loadMap(venue_id, map_id);
        this.modalRef.hide();
    }

    viewerGoTo(event) {
        this.mapService.viewerGoTo(event);
    }

    viewerLoad360FromElement(event) {
        this.mapService.viewerLoad360FromElement(event);
    }

    popoverDestroy() {
        this.mapService.popoverDestroy();
    }

    windowLoop() {
        this.mapService.windowLoop();
    }
}
